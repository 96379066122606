<template>
  <div style="height: calc(100% - (64px))">
    <Sidebar/>
    <Toolbar/>
    <div class="layout-padding">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import Toolbar from "@/components/Toolbar"

export default {
  components: {Sidebar, Toolbar},
  name: 'Layout',
};
</script>

<style scoped>
.layout-padding {
  height: 100%;
}
</style>