<template>
  <v-app-bar
      app
      color="white"
      :dense="true"
  >
    <v-app-bar-nav-icon @click="openSidebar()"></v-app-bar-nav-icon>
    <v-breadcrumbs
        :items="crumbs"
        divider="/"
        link
    ></v-breadcrumbs>
    <!--    {{crumbs}}-->
    <v-spacer></v-spacer>

    <div class="text-center">
      <v-btn
          v-if="!$store.state.isAuthenticated"
          large
          text
          @click="$router.push('/login')"
      >
        ลงชื่อเข้าใช้งาน
      </v-btn>
      <v-menu v-else offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              large
              text
              v-bind="attrs"
              v-on="on"
          >
            {{ user.first_name }} {{ user.last_name }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title @click="logout" class="text-error">ออกจากระบบ</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data: () => ({
    // user: {}
  }),
  created() {
    // this.user = this.$store.state.authUser
  },
  methods: {
    // getUserData(){
    //   this.user = this.$store.state.authUser
    // },
    logout() {
      this.$store.commit('removeToken')
      this.$router.push({path: "/login"})
    },
    openSidebar() {
      // this.drawer = false
      this.$store.commit('setDrawer', !this.drawer)
    }
  },
  computed: {
    ...mapGetters(['drawer']),
    crumbs() {
      let pathArray = this.$route.path.split("/")
      pathArray.shift()
      let href = ''
      return pathArray.reduce((breadcrumbArray, path, idx) => {
        href += '/' + path
        // console.log(path,idx,this.$route.matched[idx])
        breadcrumbArray.push({
          path: path,
          // href: breadcrumbArray[idx - 1]
          //     ? "/" + breadcrumbArray[idx - 1].path + "/" + path
          //     : "/" + path,
          href: href,
          text: (this.$route.matched[idx].meta.hasId ? `${this.$route.matched[idx].meta.breadCrumb} ${path}` : this.$route.matched[idx].meta.breadCrumb) || path,
        });
        // console.log(breadcrumbArray[idx])
        return breadcrumbArray;
      }, []);
    },
    user() {
      return this.$store.state.authUser
    }
  }
}
</script>

<style scoped>
.text-error {
  color: red;
  cursor: pointer;
}
</style>