<template>
  <v-navigation-drawer :value="drawer" dark app color="sidebar_background">
    <!--    <v-list class="layout-drawer">-->
    <!--      <v-list-item-->
    <!--          active-class="active"-->
    <!--          v-for="item in items"-->
    <!--          :key="item.id"-->
    <!--          link-->
    <!--          @click="$router.push({path:item.path})"-->

    <!--      >-->
    <!--        <v-list-item-icon v-if="item.meta.icon !== null">-->
    <!--          <v-icon>{{ item.meta.icon }}</v-icon>-->
    <!--        </v-list-item-icon>-->

    <!--        <v-list-item-content v-if="item.meta.icon !== null">-->
    <!--          <v-list-item-title>{{ item.meta.breadCrumb }}</v-list-item-title>-->
    <!--        </v-list-item-content>-->
    <!--      </v-list-item>-->
    <!--      <v-list-group>-->
    <!--        <template #activator="{active}">-->
    <!--          <v-list-item-->
    <!--              active-class="active"-->
    <!--              link-->
    <!--          >-->
    <!--            <v-list-item-icon>-->
    <!--              <v-icon>mdi-eyes</v-icon>-->
    <!--            </v-list-item-icon>-->

    <!--            <v-list-item-content>-->
    <!--              <v-list-item-title>sdsd</v-list-item-title>-->
    <!--            </v-list-item-content>-->
    <!--          </v-list-item>-->
    <!--        </template>-->

    <!--        <v-list-item>Item 1-1</v-list-item>-->
    <!--        <v-list-item>Item 1-2</v-list-item>-->
    <!--        <v-list-item>Item 1-2</v-list-item>-->
    <!--      </v-list-group>-->
    <!--    </v-list>-->

    <!--    <v-list>-->
    <!--      <v-list-item>-->
    <!--        <v-list-item-icon>-->
    <!--          <v-icon>mdi-home</v-icon>-->
    <!--        </v-list-item-icon>-->

    <!--        <v-list-item-title>Home</v-list-item-title>-->
    <!--      </v-list-item>-->

    <!--      <v-list-group-->
    <!--          no-action-->
    <!--          :value="false"-->
    <!--          prepend-icon="mdi-account-circle"-->
    <!--          class="white&#45;&#45;text text-danger"-->
    <!--          active-class="green text-danger"-->
    <!--      >-->
    <!--        <template v-slot:activator>-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>Actions</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--          &lt;!&ndash;          <v-list-item>&ndash;&gt;-->
    <!--          &lt;!&ndash;            <v-list-item-icon>&ndash;&gt;-->
    <!--          &lt;!&ndash;              <v-icon>mdi-home</v-icon>&ndash;&gt;-->
    <!--          &lt;!&ndash;            </v-list-item-icon>&ndash;&gt;-->

    <!--          &lt;!&ndash;            <v-list-item-title>Home</v-list-item-title>&ndash;&gt;-->
    <!--          &lt;!&ndash;          </v-list-item>&ndash;&gt;-->
    <!--        </template>-->

    <!--        <v-list-item-->
    <!--            v-for="([title, icon], i) in cruds"-->
    <!--            :key="i"-->
    <!--            link-->
    <!--            active-class="text&#45;&#45;white"-->
    <!--        >-->
    <!--          <v-list-item-title v-text="title"></v-list-item-title>-->

    <!--          <v-list-item-icon>-->
    <!--            <v-icon v-text="icon"></v-icon>-->
    <!--          </v-list-item-icon>-->
    <!--        </v-list-item>-->
    <!--      </v-list-group>-->
    <!--    </v-list>-->
    <v-list>
      <v-list-item-group
          v-model="selectedItem"
          color="background"
      >
        <template v-for="route in routes">
          <v-list-item
              v-if="!route.children"
              v-show="route.show"
              color="background"
              :key="route.text"
              :to="route.path"
              @click="closeSidebar()"
          >
            <v-list-item-icon>
              <v-icon>{{ route.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="route.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--          <v-list-group-->
          <!--              v-else-->
          <!--              color="indigo"-->
          <!--              :group="route.path"-->
          <!--          >-->
          <!--            <template #activator>-->
          <!--              <v-list-item-content>-->
          <!--                <v-list-item-title>-->
          <!--                  <v-icon>{{ route.icon }}</v-icon>-->
          <!--                  {{ route.text }}-->
          <!--                </v-list-item-title>-->
          <!--              </v-list-item-content>-->
          <!--            </template>-->
          <!--            <template v-for="(subSubItem, k) in route.children">-->
          <!--              <v-list-item-->
          <!--                  :key="`subheader-${k}`"-->
          <!--                  color="indigo"-->
          <!--                  :value="true"-->
          <!--              >-->
          <!--                <v-list-item-title>{{ subSubItem.text }}</v-list-item-title>-->
          <!--                <v-list-item-icon>-->
          <!--                  <v-icon>{{ subSubItem.icon }}</v-icon>-->
          <!--                </v-list-item-icon>-->
          <!--              </v-list-item>-->
          <!--            </template>-->
          <!--          </v-list-group>-->
          <v-list-group
              v-else
              v-show="implication(isMobile(),route.mobile)"
              no-action
              :value="false"
              :prepend-icon="route.icon"
              :key="route.text"
              color="background"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ route.text }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                class="pl-10"
                v-for="(subRoute, i) in route.children"
                :key="i"
                link
                :to="subRoute.path"
                @click="closeSidebar()"
            >
              <v-list-item-icon>
                <v-icon>{{ subRoute.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="subRoute.text"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      // items: [],
      // items: [
      //   {text: 'My Files', icon: 'mdi-folder'},
      //   {text: 'Shared with me', icon: 'mdi-account-multiple'},
      //   {text: 'Starred', icon: 'mdi-star'},
      //   {text: 'Recent', icon: 'mdi-history'},
      //   {text: 'Offline', icon: 'mdi-check-circle'},
      //   {text: 'Uploads', icon: 'mdi-upload'},
      //   {text: 'Backups', icon: 'mdi-cloud-upload'},
      // ],
      // cruds: [
      //   ['Create', 'mdi-plus-outline'],
      //   ['Read', 'mdi-file-outline'],
      //   ['Update', 'mdi-update'],
      //   ['Delete', 'mdi-delete'],
      // ],
      selectedItem: null,
      routes: [
        {
          text: 'หน้าแรก',
          path: '/',
          icon: 'mdi-home',
          mobile: true,
          show: true
        },
        {
          text: 'Dashboard',
          path: '/dashboard',
          icon: 'mdi-view-dashboard',
          mobile: true,
          show: true
        },
        {
          text: 'ออเดอร์',
          path: '/order',
          icon: 'mdi-view-list',
          mobile: false,
          show: this.hasPermission('view_order') && !this.isMobile()
        },
        {
          text: 'คลังสินค้า',
          path: '/stock',
          icon: 'mdi-widgets',
          mobile: false,
          show: this.hasPermission('view_product') && !this.isMobile()
        },
        {
          text: 'ติดตามค่าน้ำมัน',
          path: '/oil-tracking',
          icon: 'mdi-oil',
          mobile: false,
          show: this.hasPermission('view_oiltransaction') && !this.isMobile()
        },
        {
          text: 'การซ่อมบำรุง',
          path: '/maintenance',
          icon: 'mdi-wrench',
          mobile: false,
          show: this.hasPermission('view_maintenance') && !this.isMobile()
        },
        {
          text: 'ค่าคอมมิชชั่น',
          path: '/salary',
          icon: 'mdi-currency-btc',
          mobile: false,
          // show: this.hasPermission('view_commission') && !this.isMobile()
          show: this.hasPermission('view_commission') && !this.isMobile()
        },
        {
          text: 'ข้อมูลลูกค้า',
          path: '/setting/customer',
          icon: 'mdi-account-multiple',
          mobile: false,
          // show: this.hasPermission('view_commission') && !this.isMobile()
          show: this.hasPermission('view_customer') && !this.isMobile()
        },
        {
          text: 'รายงาน',
          path: '/report',
          icon: 'mdi-file-document',
          show: this.hasPermission('view_order') && !this.isMobile(),
          children: [
            {
              text: 'รายงานยอดรับชำระ',
              path: '/report/received',
              icon: 'mdi-cash'
            },
            {
              text: 'รายงานยอดขาย',
              path: '/report/sale',
              icon: 'mdi-chart-line'
            },
            {
              text: 'รายงานยอดค้างชำระ',
              path: '/report/overdue',
              icon: 'mdi-clipboard-alert'
            },
            {
              text: 'รายการซื้อสินค้า',
              path: '/report/product-buy',
              icon: 'mdi-clipboard-account'
            },
          ],
        },
        // {
        //   text: 'ตั้งค่า',
        //   path: '/setting',
        //   icon: 'mdi-nut',
        //   mobile: false,
        //   show: this.hasPermission('view_order') && !this.isMobile()
        // },
      ]
    }
  },
  methods: {
    closeSidebar() {
      // this.drawer = false
      this.$store.commit('setDrawer', false)
    },
    isMobile() {
      return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    implication(A, B) {
      if (A) {
        return B;
      } else {    /* if A is false, the implication is true */
        return true;
      }
    },
    hasPermission(permission) {
      return this.$store.state.authUser.user_permissions.includes(permission)
    }
  },
  computed: {
    ...mapGetters(['drawer'])
  }
}
</script>

<style scoped>
.layout-drawer {
  padding-bottom: 0px;
  padding-top: 0px;
}

.layout-drawer__icon {
  margin-bottom: 8px;
  margin-top: 8px;
}

.active {
  opacity: .5;
}
</style>